<template>
  <v-col class="px-0 py-0">
    <div class="cardBackround">
      <v-main>
        <v-container>
          <page-step
            :pages="[{ id: 1, name: 'Наші маршрути', link: '/routes' }]"
          />
          <v-row no-gutters justify="center">
            <search-ticket-form style="margin-top: 20px" />
          </v-row>
        </v-container>
      </v-main>
    </div>
    <v-main>
      <v-container style="margin-top: 0px">
        <dates-row />
        <v-row no-gutters justify="center">
          <v-col cols="12" xl="7" lg="9" md="9" sm="12" class="px-0 py-0">
            <p class="quantityTrip" style="margin-top: 40px">
              {{ "found_label" | localize }}
              <span style="color: #144fa9; margin-top: 20px">{{
                tripList.length
              }}</span>
              {{ "buses_label" | localize }}
            </p>
            <p class="tripText" style="margin-bottom: 20px">
              {{ "time_zone_warning" | localize }}
            </p>
            <loader v-if="showLoader" />
            <v-col v-else class="px-0 py-0">
              <ticket-search-card
                v-for="trip in tripList"
                :key="trip.id"
                :trip="trip"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-col>
</template>

<script>
import searchRoutesService from "../../../requests/User/searchRoutesService";
import TicketSearchCard from "../../UI/Cards/ticketSearchCard.vue";
import DatesRow from "../../UI/Forms/datesRow.vue";
import searchTicketForm from "../../UI/Forms/searchTicketForm.vue";
import Loader from "../../UI/Loader.vue";
import PageStep from "../../UI/pageStep.vue";
export default {
  components: {
    searchTicketForm,
    PageStep,
    TicketSearchCard,
    Loader,
    DatesRow,
  },
  data: () => ({
    tripList: [],
    showLoader: true,
  }),
  mounted() {
    this.searchTrips();
  },
  methods: {
    async searchTrips() {
      await searchRoutesService
        .searchTrip(
          this.$route.params.departure_city,
          this.$route.params.arrival_city,
          this.$route.query.departureDate,
          this.$route.query.qty
        )
        .then((res) => {
          if (res.status == "Success") {
            this.tripList = res.data.current_date.map(
              (trip) =>
                (trip = {
                  ...trip,
                  arrival_stations: trip.arrival_stations?.[0],
                  departure_stations: trip.departure_stations?.[0],
                })
            );
            this.showLoader = false;
          }
        });
    },
  },
  watch: {
    "$route.path": {
      deep: true,
      handler() {
        this.showLoader = true;
        this.searchTrips();
      },
    },
  },
};
</script>

<style scoped>
.cardBackground {
  padding: 20px 0px;
  background: #fff;
}
.quantityTrip {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}
.tripText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
</style>
