<template>
  <ticket-seach-component />
</template>

<script>
import ticketSeachComponent from '../../components/User/ticketSearch/ticketSeachComponent.vue'
export default {
  components: { ticketSeachComponent },
  mounted(){
    this.$vuetify.goTo(0,0)
  },
}
</script>

<style>

</style>