var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticStyle:{"margin-top":"30px"},attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"swipeBtn",style:(_vm.disablePrevIcon ? 'pointer-events: none;' : ''),on:{"click":_vm.prevSlide}},[_c('div',{staticClass:"swipeArrow",style:(_vm.disablePrevIcon ? 'background-color:#b5b5b5' : '')})]),_c('div',{staticClass:"swipeContent"},[_c('v-slide-group',{attrs:{"center-active":""},scopedSlots:_vm._u([{key:"next",fn:function(){return [_c('v-icon',{staticStyle:{"display":"none","pointer-events":"none"}})]},proxy:true}]),model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.daysList),function(date,index){return _c('v-slide-item',{key:index},[_c('div',{staticClass:"slideItem",class:new Date(date).toLocaleDateString('uk-UA', {
              weekday: 'short',
              day: 'numeric',
              timeZone: 'Europe/Kiev',
            }) ==
            new Date(_vm.activeDate).toLocaleDateString('uk-UA', {
              weekday: 'short',
              day: 'numeric',
              timeZone: 'Europe/Kiev',
            })
              ? 'activeSlideItem'
              : new Date(date) < new Date(new Date().getTime() - 86400000)
              ? 'inActiveSlideItem'
              : '',on:{"click":function($event){return _vm.setActiveDate(1, date)}}},[_vm._v(" "+_vm._s(new Date(date).toLocaleDateString("uk-UA", { weekday: "short", day: "numeric", timeZone: "Europe/Kiev", }))+" ")])])}),1)],1),_c('div',{staticClass:"swipeBtn",on:{"click":_vm.nextSlide}},[_c('div',{staticClass:"swipeArrow",staticStyle:{"transform":"scaleX(-1)"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }